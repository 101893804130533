import React from "react"
import { AiOutlineHome } from "react-icons/ai"

function Home() {
	return (
		<div className="home">
			<h2>Startseite</h2>
			<AiOutlineHome className="page-icon" />
		</div>
	)
}

export default Home
