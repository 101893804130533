import React from 'react'
// import { BsNewspaper } from "react-icons/bs"
import { FaPeopleRoof } from 'react-icons/fa6'
// import { SiWwe } from 'react-icons/si'

function About() {
	return (
		<div className='about'>
			<h2>Wir</h2>
			<FaPeopleRoof className='page-icon' />
		</div>
	)
}

export default About
