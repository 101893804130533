import React from "react"
import { AiOutlineMail } from "react-icons/ai"

function Contact() {
	return (
		<div className="contact">
			<h2>Kontakt</h2>
			<AiOutlineMail className="page-icon" />
		</div>
	)
}

export default Contact
