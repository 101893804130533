import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css'

import { GiPlagueDoctorProfile } from 'react-icons/gi'
// import { SiWwe } from 'react-icons/si'

import { FaBars, FaTimes } from 'react-icons/fa'
import { IconContext } from 'react-icons/lib'
import { NavLink } from 'react-router-dom'

function Navbar() {
	const [click, setClick] = useState(false)

	const handleClick = () => setClick(!click)
	const closeMobileMenu = () => setClick(false)

	return (
		<>
			<IconContext.Provider value={{ color: '#fff' }}>
				<nav className='navbar'>
					<div className='navbar-container container'>
						<Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
							<GiPlagueDoctorProfile className='navbar-icon' />
							TB
						</Link>

						<div className='menu-icon' onClick={handleClick}>
							{click ? <FaTimes /> : <FaBars />}
						</div>

						<ul className={click ? 'nav-menu active' : 'nav-menu'}>
							<li className='nav-item'>
								<NavLink
									to='/schutz'
									className={({ isActive }) => 'nav-links' + (isActive ? ' activated' : '')}
									onClick={closeMobileMenu}
								>
									Schutz
								</NavLink>
							</li>

							<li className='nav-item'>
								<NavLink
									to='/services'
									className={({ isActive }) => 'nav-links' + (isActive ? ' activated' : '')}
									onClick={closeMobileMenu}
								>
									Service
								</NavLink>
							</li>

							<li className='nav-item'>
								<NavLink
									to='/about'
									className={({ isActive }) => 'nav-links' + (isActive ? ' activated' : '')}
									onClick={closeMobileMenu}
								>
									Wir
								</NavLink>
							</li>

							<li className='nav-item'>
								<NavLink
									to='/contact'
									className={({ isActive }) => 'nav-links' + (isActive ? ' activated' : '')}
									onClick={closeMobileMenu}
								>
									Kontakt
								</NavLink>
							</li>
						</ul>
					</div>
				</nav>
			</IconContext.Provider>
		</>
	)
}

export default Navbar
