import React from 'react'
// import { BsNewspaper } from "react-icons/bs"
// import { FaPeopleRoof } from 'react-icons/fa6'
import { BsVirus } from 'react-icons/bs'

function Schutz() {
	return (
		<div className='schutz'>
			<h2>Schutz</h2>
			<BsVirus className='page-icon' />
		</div>
	)
}

export default Schutz
