import React from 'react'
import { createRoot } from 'react-dom/client'
import { createBrowserRouter } from 'react-router-dom'
import { RouterProvider } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
// import { Route, Link } from "react-router-dom"

import './index.css'

import Navbar from './components/Navbar/Navbar'

import Home from './routes/Home'
import Schutz from './routes/Schutz'
import Services from './routes/Services'
import About from './routes/About'
import Contact from './routes/Contact'
import ErrorPage from './routes/ErrorPage'

const AppLayout = () => {
	return (
		<>
			<Navbar />
			<Outlet />
		</>
	)
}

const router = createBrowserRouter([
	{
		element: <AppLayout />,
		errorElement: <ErrorPage />,
		children: [
			{
				path: '/',
				element: <Home />,
			},
			{
				path: 'schutz',
				element: <Schutz />,
			},
			{
				path: 'services',
				element: <Services />,
			},
			{
				path: 'about',
				element: <About />,
			},
			{
				path: 'contact',
				element: <Contact />,
			},
		],
	},
])

createRoot(document.getElementById('root')).render(<RouterProvider router={router} />)
