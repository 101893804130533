import React from "react"
// import { AiOutlineHome } from "react-icons/ai"
import { MdOutlineMedicalServices } from "react-icons/md"

function Services() {
	return (
		<div className="services">
			<h1>Service</h1>
			<MdOutlineMedicalServices className="page-icon" />
		</div>
	)
}

export default Services
