import { useRouteError } from "react-router-dom"
import "../index.css"

export default function ErrorPage() {
	const error = useRouteError()

	return (
		<div class="error-page" id="error-page">
			<h2>Fehler!</h2>
			{/* <h4>Dies ist eine Fehlerseite.</h4> */}
			{/* <p> */}
			{/* <p>&nbsp;</p> */}
			{/* <h5>Meldung:</h5> */}
			{/* <p> */}
			{error.statusText || error.message}
			{/* </p> */}
			{/* </p> */}
		</div>
	)
}
